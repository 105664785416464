<script>

import icon from '@/assets/images/mkm-172x128-19.jpg'

export default {
    name: 'Intro',
    data: function () {
      return {
        icon: icon
      }
    }
  };

</script>

<template>

  <div class="banner">
    <div style="width: 100%;">
     <div style="width: 100px; float: left;">
      <div class="companyname">
        <router-link to="/">
          <img alt="img" class="icon" :src="icon" />
        </router-link> 
      </div>
     </div>
     <div style="margin: auto;"> 
    <nav>
        <router-link to="/">Home</router-link> |
        <router-link to="/apps">Mobile Apps</router-link> |
        <router-link to="/techvideos">Tech Videos</router-link> | 
        <router-link to="/art">Art</router-link> |
        <router-link to="/music">Music</router-link> |
        <router-link to="/analytics">Analytics</router-link>
      </nav> 
    </div>
  </div>

  </div>
  <router-view/>

</template>

<style>

.icon {
  display: block;
  max-width:150px;
  max-height:75px;
  width: auto;
  height: auto;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.banner{
  width: 100%;
  background-color: black;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: lightslategrey;
}

nav a.router-link-exact-active {
  color: White;
  font-size: x-large;
}

.companyname{
  vertical-align: bottom;
  color: white;
  font-size: xx-large;
  margin-top: 5px;
  margin-left: 5px;
}

</style>
