import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'intro',
    component: () => import( '../views/IntroView.vue')
  },
  {
    path: '/apps',
    name: 'apps',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MobileAppsView.vue')
  },
  {
    path: '/techvideos',
    name: 'design',
    component: () => import( '../views/DesignPatternsView.vue')
  },
  {
    path: '/development',
    name: 'development',
    component: () => import( '../views/DevelopmentServicesView.vue')
  },
  {
    path: '/art',
    name: 'artprojects',
    component: () => import( '../views/ArtProjectsView.vue')
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import( '../views/ArticlesView.vue')
  },
  {
    path: '/analytics',
    name: 'musicanalytics',
    component: () => import( '../views/MusicAnalytics.vue')
  },
  {
    path: '/music',
    name: 'music',
    component: () => import( '../views/MusicView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
